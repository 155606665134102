import { createContext, Context, useContext, useEffect, useState } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';


interface Request {
    msg: any,
    msgListener: (msg: any) => void
}

export interface Error {
    requestId: number,
    error: string
}

export interface ListResponse {
    requestId: number,
    maxSize?: number,
    elements: [{
        valueId: string,
        value?: any
    }],
    nodeDeleted?: string,
    isChangeMessage?: boolean,
    isFirstMessage?: boolean
}

export interface MapResponse {
    requestId: number,
    elements: [{
        key: string,
        valueId?: string,
        value?: any
    }],
    isFirstMessage?: boolean
}

export interface ValueResponse {
    requestId: number,
    value?: any,
    nodeDeleted?: string
}

export class RtgDB {

    basePath: string
    ws: ReconnectingWebSocket
    currentId: number = 0
    requests: Map<number, Request> = new Map()
    closeCallback: NodeJS.Timeout | undefined = undefined
    debug: boolean

    constructor(basePath: string, onError: (error: Error) => void = (error) => console.error(error), debug: boolean = false) {
        this.debug = debug
        this.basePath = basePath
        if (debug) console.log("creating new reconnecting websocket " + basePath)
        this.ws = new ReconnectingWebSocket(basePath, [], { startClosed: true, debug: false })

        this.ws.addEventListener('open', (_) => {
            for (const request of this.requests.values()) {
                this.ws.send(JSON.stringify(request.msg))
            }
        })

        this.ws.addEventListener('message', (e) => {
            const data = JSON.parse(e.data)
            if (data.requestId == undefined || data.requestId == null) {
                onError({ requestId: -1, error: "message does not have requestId field: " + JSON.stringify(data) })
                return
            }
            if (!this.requests.has(data.requestId)) {
                onError({ requestId: -1, error: "message for unknown requestId: " + JSON.stringify(data) })
                return
            }
            if (data.error) {
                onError({ requestId: data.requestId, error: data.error })
                return
            }
            if (debug) console.log("received message for request", data.requestId)
            this.requests.get(data.requestId).msgListener(data)
        })
    }


    closeRequest(id: number) {
        if (this.debug) console.log("closing request", id)
        this.requests.delete(id)
        this.ws.send(JSON.stringify({ requestId: id, type: 'close' }))

        if (this.requests.size == 0)
            this.closeCallback = setTimeout(() => {
                if (this.requests.size == 0) {
                    if (this.debug) console.log("closing websocket")
                    this.ws.close()
                }
            }, 5000)
    }

    openRequest(msg: any, msgListener: (msg: any) => void): () => void {
        if (this.closeCallback) clearTimeout(this.closeCallback)
        const id = this.currentId++
        if (this.debug) console.log("sending request", msg, "with id", id)
        msg.requestId = id
        this.requests.set(id, { msg, msgListener })
        if (this.ws.readyState == 3 || this.ws.readyState == 2) {// CLOSED || CLOSING
            if (this.debug) console.log("reconnecting websocket")
            this.ws.reconnect()
        } else if (this.ws.readyState == 1) { // OPEN
            this.ws.send(JSON.stringify(msg))
        }

        return () => this.closeRequest(id)
    }

    queryListById(id: string, msgListener: (msg: ListResponse) => void, resolveNodes?: boolean): () => void {
        return this.openRequest({ id, resolveNodes, type: 'list' }, msgListener)
    }
    queryListByName(name: string, msgListener: (msg: ListResponse) => void, resolveNodes?: boolean): () => void {
        return this.openRequest({ name, resolveNodes, type: 'list' }, msgListener)
    }
    queryMapById(id: string, msgListener: (msg: MapResponse) => void, resolveNodes?: boolean): () => void {
        return this.openRequest({ id, resolveNodes, type: 'map' }, msgListener)
    }
    queryMapByName(name: string, msgListener: (msg: MapResponse) => void, resolveNodes?: boolean): () => void {
        return this.openRequest({ name, resolveNodes, type: 'map' }, msgListener)
    }
    queryValueById(id: string, msgListener: (msg: ValueResponse) => void): () => void {
        return this.openRequest({ id, type: 'value' }, msgListener)
    }
    queryValueByName(name: string, msgListener: (msg: ValueResponse) => void): () => void {
        return this.openRequest({ name, type: 'value' }, msgListener)
    }

}


export const createDB = (basePath: string, onError?: (error: Error) => void) => createContext(new RtgDB(basePath, onError))

export interface Node<T> {
    valueId: string,
    value?: T
}

export function useListById<T>(dbContext: Context<RtgDB>, id: string, resolveNodes?: boolean): Node<T>[] {
    const db = useContext(dbContext)
    const [list, setList] = useState<Node<T>[]>([])

    useEffect(() => {
        let maxSize = -1
        let list: Node<T>[] = []
        return db.queryListById(id, (msg) => {
            if (msg.maxSize)
                maxSize = msg.maxSize
            if (msg.isFirstMessage)
                list = []
            if (msg.isChangeMessage) {
                for (const changed of msg.elements) {
                    for (const e of list) {
                        if (e.valueId == changed.valueId)
                            e.value = changed.value
                    }
                }
            }
            else if (msg.nodeDeleted) {
                list = list.filter(e => e.valueId !== msg.nodeDeleted)
            }
            else {
                list.push(...msg.elements)
                while (maxSize >= 0 && list.length > maxSize)
                    list.shift()
            }
            setList([...list])
        }, resolveNodes)
    }, [db, id, resolveNodes])

    return list
}

export function useListByName<T>(dbContext: Context<RtgDB>, name: string, resolveNodes?: boolean): Node<T>[] {
    const db = useContext(dbContext)
    const [list, setList] = useState<Node<T>[]>([])

    useEffect(() => {
        let maxSize = -1
        let list: Node<T>[] = []
        return db.queryListByName(name, (msg) => {
            if (msg.maxSize)
                maxSize = msg.maxSize
            if (msg.isFirstMessage)
                list = []
            if (msg.isChangeMessage) {
                for (const changed of msg.elements) {
                    for (const e of list) {
                        if (e.valueId == changed.valueId)
                            e.value = changed.value
                    }
                }
            }
            else if (msg.nodeDeleted) {
                list = list.filter(e => e.valueId !== msg.nodeDeleted)
            }
            else {
                list.push(...msg.elements)
                while (maxSize >= 0 && list.length > maxSize)
                    list.shift()
            }
            setList([...list])
        }, resolveNodes)
    }, [db, name, resolveNodes])

    return list
}

export function useMapById<T>(dbContext: Context<RtgDB>, id: string, resolveNodes?: boolean): { [name: string]: Node<T> } {
    const db = useContext(dbContext)
    const [map, setMap] = useState<{ [name: string]: Node<T> }>({})

    useEffect(() => {
        let map = new Map<string, Node<T>>()
        return db.queryMapById(id, (msg) => {
            if (msg.isFirstMessage)
                map = new Map<string, Node<T>>()
            for (const element of msg.elements) {
                if (element.valueId)
                    map.set(element.key, { value: element.value, valueId: element.valueId })
                else
                    map.delete(element.key)
            }
            const newMap = {}
            map.forEach((v, k) => {
                newMap[k] = v
            })
            setMap(newMap)
        }, resolveNodes)
    }, [db, id, resolveNodes])

    return map
}

export function useMapByName<T>(dbContext: Context<RtgDB>, name: string, resolveNodes?: boolean): { [name: string]: Node<T> } {
    const db = useContext(dbContext)
    const [map, setMap] = useState<{ [name: string]: Node<T> }>({})

    useEffect(() => {
        let map = new Map<string, Node<T>>()
        return db.queryMapByName(name, (msg) => {
            if (msg.isFirstMessage)
                map = new Map<string, Node<T>>()
            for (const element of msg.elements) {
                if (element.valueId)
                    map.set(element.key, { value: element.value, valueId: element.valueId })
                else
                    map.delete(element.key)
            }
            const newMap = {}
            map.forEach((v, k) => {
                newMap[k] = v
            })
            setMap(newMap)
        }, resolveNodes)
    }, [db, name, resolveNodes])

    return map
}

export function useValueById<T>(dbContext: Context<RtgDB>, id: string): T | null {
    const db = useContext(dbContext)
    const [value, setValue] = useState<T | null>(null)

    useEffect(() => {
        return db.queryValueById(id, (msg) => {
            if (msg.nodeDeleted)
                setValue(null)
            else
                setValue(msg.value)
        })
    }, [db, id])

    return value
}

export function useValueByName<T>(dbContext: Context<RtgDB>, name: string): T | null {
    const db = useContext(dbContext)
    const [value, setValue] = useState<T | null>(null)

    useEffect(() => {
        return db.queryValueByName(name, (msg) => {
            setValue(msg.value)
        })
    }, [db, name])

    return value
}

export const wsBackend = (relativeUrl: string) => {
    if (process.env.REACT_APP_WS_BACKEND) {
        return process.env.REACT_APP_WS_BACKEND + relativeUrl
    }
    return "ws" + (window.location.protocol === 'https:' ? 's' : '') + "://" + window.location.host + relativeUrl
}