import React, { useState } from 'react'
//import styles from './styles.module.css'
import { ThemeProvider, createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Typography, IconButton, ListItemText, List, ListItem, ListItemIcon, Drawer, Divider, CssBaseline, Container, SimplePaletteColorOptions, Color } from '@material-ui/core'
import { Menu as MenuIcon, Brightness4 as BrightnessIcon } from '@material-ui/icons'
import { FC } from 'react';
import clsx from 'clsx';
import { AllesLogo } from './logo';
import { teal, deepPurple } from '@material-ui/core/colors';
import { useLocalStorage } from './hooks';
import { AlertBarProvider, useAlertBar } from './alerts';
import { createDB, RtgDB, ValueResponse, ListResponse, MapResponse, Error, Node, useValueByName, useValueById, useListByName, useListById, useMapByName, useMapById, wsBackend } from './RtgClient'
import { Loading } from './Loading'
import { Link as RouterLink } from 'react-router-dom';

export { AllesLogo, useLocalStorage, AlertBarProvider, useAlertBar, createDB, RtgDB, useValueByName, useValueById, useListByName, useListById, useMapByName, useMapById, ValueResponse, ListResponse, MapResponse, Error, Node, wsBackend, Loading }


interface ColorTheme {
  primaryColor: SimplePaletteColorOptions | Partial<Color>,
  secondaryColor: SimplePaletteColorOptions | Partial<Color>,
}

interface Props {
  name: string,
  darkTheme?: ColorTheme,
  lightTheme?: ColorTheme,
  sidebar(classes, open: boolean): React.ReactNode,
  topbar?: React.ReactNode,
}


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexFlow: "column",
    flexGrow: 0,
    flexShrink: 1,
    height: "100vh",
    width: "100vw",
    overflow: "hidden",
  },
  logo: {
    height: "2.5em",
    width: "auto",
    marginRight: theme.spacing(2),
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(3) + "px !important" as any
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  appContainer: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    minHeight: 0,
  },
  drawer: {
    position: "relative",
    whiteSpace: "nowrap",
    overflowY: "auto",
    flexShrink: 0,
  },
  drawerEntryName: {
    transition: theme.transitions.create(['max-width'], {
      duration: theme.transitions.duration.leavingScreen + 200,
    }),
    maxWidth: "50vw",
    overflowX: "hidden",
  },
  drawerEntryNameClosed: {
    transition: theme.transitions.create(['max-width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen + 200,
    }),
    maxWidth: "0",
  },
  drawerIcon: {
    minWidth: "0 !important",
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100%",
    overflow: "auto",
    display: 'flex',
    flexDirection: 'column'
  },
  brightnessIcon: {
    marginLeft: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  fixedHeight: {
    height: 240
  }
}));


export const AllesApp: FC<Props> = ({ name, children, darkTheme, lightTheme, sidebar, topbar }) => {
  darkTheme = darkTheme || {
    primaryColor: {
      main: teal[500]
    },
    secondaryColor: {
      main: deepPurple[500]
    },
  }
  lightTheme = lightTheme || darkTheme

  const [open, setOpen] = useState(false);
  const [darkState, setDarkState] = useLocalStorage('darkMode', true);

  const handleThemeChange = () => {
    setDarkState(!darkState);
  };

  const handleDrawerToggle = () => {
    if (sidebar)
      setOpen(open => !open);
  };


  const theme = createMuiTheme({
    palette: {
      primary: darkState ? darkTheme?.primaryColor : lightTheme?.primaryColor,
      secondary: darkState ? darkTheme?.secondaryColor : lightTheme?.secondaryColor,
      type: darkState ? 'dark' : 'light',
    },
  })
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <AlertBarProvider>

        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="relative"
            className={clsx(classes.appBar)}
          >
            <Toolbar className={classes.toolbar}>
              {sidebar ? <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
                : undefined}

              <AllesLogo color={theme.palette.primary.contrastText} className={classes.logo} />
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                {name}
              </Typography>
              {topbar}
              <IconButton
                edge="start"
                color="inherit"
                aria-label="change theme"
                onClick={handleThemeChange}
                className={classes.brightnessIcon}
              >
                <BrightnessIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className={classes.appContainer}>
            {sidebar ?
              <Paper className={classes.drawer}>
                <Divider />
                <List>
                  {sidebar(classes, open)}
                </List>
              </Paper>
              : undefined
            }
            <div className={classes.content}>
              {children}
            </div>
          </div>
        </div>
      </AlertBarProvider>
    </ThemeProvider>
  );
}


