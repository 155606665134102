import React, { useState, useContext } from 'react';
import { Snackbar } from '@material-ui/core';

const AlertBar = React.createContext<(alert: React.ReactNode) => void>(() => { });

interface AlertState {
    alert: React.ReactNode | ((close: () => void) => React.ReactNode),
    open: boolean,
    key: number
}

export const AlertBarProvider: React.FC<{}> = ({ children }) => {
    const [alert, setAlert] = useState<AlertState | undefined>(undefined)
    const [n, setN] = useState(0)

    const showAlert = (alert: React.ReactNode) => {
        setAlert({ alert: alert, open: true, key: n })
        setN(n + 1)
    }

    const closeAlert = () => {
        setAlert({ ...alert, open: false })
    }
    return <AlertBar.Provider value={showAlert}>
        {alert ? <Snackbar open={alert.open} autoHideDuration={6000} onClose={closeAlert} key={alert.key}>
            <React.Fragment>{typeof alert.alert == 'function' ? (alert.alert as ((close: () => void) => React.ReactNode))(closeAlert) : alert.alert}</React.Fragment>
        </Snackbar> : undefined}
        {children}
    </AlertBar.Provider>
}

export const useAlertBar = () => useContext(AlertBar)