import React from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
})

interface Props {
    color?: 'inherit' | 'primary' | 'secondary',
    width?: number | string | null,
    height?: number | string | null
}

export const Loading = ({ color, width, height }: Props) => {
    const classes = useStyles()
    return <div className={classes.root} style={{ width: width === null ? undefined : (width || '100%'), height: height === null ? undefined : (height || '100%') }}><CircularProgress color={color} /></div>
}